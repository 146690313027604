import {
  FLAVIA_IMG,
  GUILLERMO_IMG,
  JACK_IMG,
  LUCY_IMG,
  RUBEN_IMG,
  STEPHEN_IMG,
  RICHARD_IMG,
  ROB_IMG,
} from '../images'

export const flavia = {
  name: 'Flavia Belham',
  title: 'Chief Scientist @ Seneca Learning',
  image: FLAVIA_IMG,
}

export const flaviaPt = {
  name: 'Flávia Belham',
  title: 'Professora Responsável @ Seneca',
  image: FLAVIA_IMG,
}

export const guillermo = {
  name: 'Guillermo González',
  title: 'Comunidad de Maestros en Seneca México',
  image: GUILLERMO_IMG,
}

export const jack = {
  name: 'Jack Holmes',
  title: 'Parent Community @ Seneca Learning',
  image: JACK_IMG,
}

export const lucy = {
  name: 'Lucy Porter',
  title: 'Teacher Community @ Seneca Learning',
  image: LUCY_IMG,
}

export const ruben = {
  name: 'Ruben Portz',
  title: 'Teacher Community @ Seneca Learning',
  image: RUBEN_IMG,
}

export const stephen = {
  name: 'Stephen Wilks',
  title: 'CEO @ Seneca Learning',
  image: STEPHEN_IMG,
}

export const richard = {
  name: 'Richard Broad',
  title: 'Head of UK Education @ Seneca Learning',
  image: RICHARD_IMG,
}

export const rob = {
  name: 'Rob Price',
  title: 'Teacher Community @ Seneca Learning',
  image: ROB_IMG,
}

export const authors = [
  flavia,
  guillermo,
  jack,
  lucy,
  richard,
  rob,
  ruben,
  stephen,
  flaviaPt,
]
