// @flow

import React from 'react'
import { useIntl } from 'react-intl'

import PageRow from 'src/common/components/PageRow'

import styles from './BlogInfo.module.scss'

type Props = {|
  image: string,
  name: string,
  title: string,
  date: string,
|}

const BlogInfo = ({ image, name, title, date }: Props) => {
  const { formatDate } = useIntl()
  const localisedDate = formatDate(date, {
    year: 'numeric',
    month: 'long',
    day: '2-digit',
  })

  return (
    <PageRow className={styles.container}>
      <img className={styles.image} src={image} />
      <div className={styles.info}>
        <div className={styles.name}>{name}</div>
        <div className={styles.title}>{title}</div>
        <div className={styles.date}>{localisedDate}</div>
      </div>
    </PageRow>
  )
}

export default BlogInfo
