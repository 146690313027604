// @flow
import React from 'react'
import classnames from 'classnames'
import { graphql, Link } from 'gatsby'
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi'

import { withIntl, useTranslateObject } from 'src/intl'

import {
  Layout,
  Navbar,
  BackgroundImageShort,
  Footer,
  Heading,
  PostContent,
  PageRow,
  SharingSection,
} from 'src/common/components'

import { ButtonLink } from 'src/common/components/Button'
import { authors } from 'src/common/authors'
import Image from 'src/common/components/Image'

import HeadTags from 'src/components/HeadTags'
import BlogInfo from 'src/components/BlogInfo'

import styles from './blog-post.module.scss'

type PageContext = {
  locale: string,
}

type Props = {
  data: Object,
  pageContext: PageContext,
}

const strings = {
  next: 'blogNavigation.next',
  previous: 'blogNavigation.previous',
}

const BlogPost = ({ data, pageContext }: Props) => {
  const { footer } = data.footer.frontmatter

  const { markdownRemark: post } = data
  const {
    intro: { title, author, date, image },
    meta: { pageTitle, metaDescription, ogImage, ...otherMeta },
  } = post.frontmatter
  const { html, excerpt } = post

  const { locale, next, prev, pagePath } = pageContext

  const authorInfo = authors.find(({ name }) => name === author) || authors[0]
  let sharelink = null
  if (typeof window !== `undefined`) {
    sharelink = window.location.href
  }

  const messages = useTranslateObject(strings)

  return (
    <>
      <HeadTags
        pageContext={pageContext}
        pageTitle={pageTitle || title}
        metaDescription={metaDescription || excerpt}
        ogImage={ogImage || image}
        {...otherMeta}
      />
      <Layout>
        {{
          navbar: <Navbar pagePath={pageContext.pagePath} />,
          background: <BackgroundImageShort />,
          footer: <Footer content={footer} />,
          body: (
            <PageRow className={styles.container} firstRow>
              <div className={styles.image}>
                <Image src={image} widths={{ desktop: 720 }} alt={title} />
              </div>
              <div className={styles.body}>
                <BlogInfo
                  date={date}
                  name={authorInfo.name}
                  title={authorInfo.title}
                  image={authorInfo.image}
                />
                <SharingSection shareText={title} shareLink={sharelink} />
                <Heading color="black" text={title} />
                <PostContent className={styles.content} content={html} />
                <div
                  className={classnames(styles.suggestedBlogs, {
                    [styles.firstBlog]: !prev,
                  })}
                >
                  {prev && (
                    <Link
                      to={`/${locale}/${pagePath}/${prev.fields.slug}`}
                      className={styles.link}
                    >
                      <FiChevronLeft />
                      {messages.previous}
                    </Link>
                  )}
                  {next && (
                    <Link
                      to={`/${locale}/${pagePath}/${next.fields.slug}`}
                      className={styles.link}
                    >
                      {messages.next} <FiChevronRight />
                    </Link>
                  )}
                </div>
              </div>
            </PageRow>
          ),
        }}
      </Layout>
    </>
  )
}

export default withIntl(BlogPost)

export const pageQuery = graphql`
  query BlogPostByID($id: String!, $locale: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      excerpt(pruneLength: 150)
      frontmatter {
        intro {
          title
          author
          date(formatString: "MMMM DD, YYYY")
          tags
          image
        }
        ...MetaFragment
      }
    }
    ...FooterFragment
  }
`
